import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"

const prdbestData = import("./data/data.json");

export default function prdbest() {

  let css;
       if(isChrome()){
         css = `.trbm-module--trbm button {
           cursor:auto;
         }
         .trbm-module--trbm button:hover {
         transform: none;
        }
         `;
       }

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-image: url(/img/bg80.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .footerlinks-module--br,
     .footerlinks-module--br a {
      color:#f1f1f1;
    }

    .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        background: #43ba19;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }
    .trbm-module--trbm button {
      background: #43ba19;
    }

    .trbm-module--trbm h1 {
      font-size: 50px;
    }

    .trbm-module--trbm .trbm-module--logo {
      margin: 5px 0px 10px;
      width: 160px;
    }
    `}
    </style>
    <style type="text/css">
     {css}
         </style>
    <title>Save any Webpage Easily - EasySave.app</title></Helmet>
    <section>
      <Trbm data={prdbestData}>
      <CtaDisclosureSearch></CtaDisclosureSearch>
    </Trbm>
      </section>
    </HomepageLayout>
  )
}
